import './App.css';
import { FaRegCopy } from "react-icons/fa6";
import { useState, useEffect } from "react";
import { useParams, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Swal from "sweetalert2"; // Importar SweetAlert2

function App() {
  const { key } = useParams(); // Obtener 'key' desde la URL
  const [tiempo, setTiempo] = useState(null);
  const [proceso, setProceso] = useState("");
  const [opciones, setOpciones] = useState([]);
  const [filteredOpciones, setFilteredOpciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para la búsqueda
  const [copiedIndex, setCopiedIndex] = useState(null); // Estado para rastrear el botón presionado

  const apiUrl = `https://apibot.monterrico.app/api/Bot/GProceso?key=${key}`;

  const fetchData = () => {
    if (!key) return; // Si no hay clave, no hace la solicitud

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setTiempo(data?.OProceso?.tiempo || null);
        setProceso(data?.OProceso?.proceso || "");
        setOpciones(data?.AInfo || []);
        setFilteredOpciones(data?.AInfo || []); // Inicializar las opciones filtradas
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(); // Ejecutar una vez al cargar el componente

    const interval = setInterval(() => {
      fetchData(); // Ejecutar cada minuto
    }, 60000); // 60000 ms = 1 minuto

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
  }, [apiUrl, key]);

  // Filtrar opciones basado en el término de búsqueda
  useEffect(() => {
    const filtered = opciones.filter((item) =>
      item.info.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOpciones(filtered);
  }, [searchTerm, opciones]);

  const handleCopy = (info, index) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(info).then(() => {
        Swal.fire({
          title: "¡Copiado!",
          text: `${info}`,
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        setCopiedIndex(index); // Actualizar el índice del botón copiado
      }).catch((error) => {
        console.error("Error al copiar:", error);
      });
    } else {
      // Fallback para navegadores sin soporte para navigator.clipboard
      const textArea = document.createElement("textarea");
      textArea.value = info;
      textArea.style.position = "fixed"; // Evita que el textarea se mueva en la pantalla
      textArea.style.opacity = 0;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        Swal.fire({
          title: "¡Copiado!",
          text: `${info}`,
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        setCopiedIndex(index); // Actualizar el índice del botón copiado
      } catch (err) {
        console.error("Error al copiar con fallback:", err);
      }

      document.body.removeChild(textArea);
    }
  };

  if (loading) {
    return <div className="content-info">Cargando...</div>;
  }

  // Verificar si tiempo es -1 para mostrar la vista de contenido expirado
  if (tiempo < 0) {
    return (
      <div className="content-expired">
        <h1>Contenido Expirado</h1>
        <p>El contenido que estás intentando acceder ya no está disponible.</p>
      </div>
    );
  }

  const progressPercentage = tiempo !== null ? Math.min((tiempo / 10) * 100, 100) : 0; // Progreso basado en tiempo

  const formattedProceso = proceso.toLowerCase() === "centrocostos" ? "Centro de Costos" : proceso.charAt(0).toUpperCase() + proceso.slice(1);

  return (
    <div className="content-info">
      <h4>Expira en {tiempo !== null ? `${tiempo} minutos` : "No disponible"}</h4>

      {/* Barra de progreso */}
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progressPercentage}%`, backgroundColor: "green", height: "10px" }}
        ></div>
      </div>

      {/* Barra de búsqueda */}
      <h1>{formattedProceso}</h1>
      <div className="info">
        <input
          type="text"
          placeholder="Buscar..."
          className="search-bar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {filteredOpciones.map((item, index) => (
          <div key={index} className="box-info">
            <p>{item.info}</p>
            <button
              className="box-button-copy"
              onClick={() => handleCopy(item.info, index)}
            >
              {copiedIndex === index ? "Copiado" : <FaRegCopy className="button-copy" />}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

// Enrutador principal
function MainApp() {
  return (
    <Router>
      <Routes>
        <Route path="/:key" element={<App />} />
      </Routes>
    </Router>
  );
}

export default MainApp;
